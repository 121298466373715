var domain = document.location.protocol + '//' + document.location.hostname;
var cookie;
var cookieNameAcd = 'accordions';
var cookieAcd;

$(function(){
  
  // open raw xml mode in new tab on ctrl+alt+r
  var url = window.location + '';
  if (location.href.split(location.host)[1].indexOf('/tangelo/page/') == 0) {
    $(window).keypress(function(e){
      var code = e.keyCode || e.which;
      if(code == 174 && e.ctrlKey && e.altKey) {
        var urlBase = url.split('?')[0];
        var urlParams = url.split('?')[1] ? '?' + url.split('?')[1] : '';
        var newUrl = urlBase + '/raw' + urlParams;
        window.open(newUrl,'_blank');
      }
    });
  }
	
  // website exit
  $.expr[':'].external = function (a) {
    return a.href.indexOf('http') == 0 && a.href.indexOf(domain) != 0;
  };
  $('a:external').each(function(){
    $(this).attr('target','_blank');
  });
  
  // build email links (spam prevention)
	$('a.email').each(function(){
		var pre       = $(this).data('pre');
		var after     = $(this).data('after');
		var textPre   = $(this).data('text-pre');
		var textAfter = $(this).data('text-after');
		$(this).attr('href', 'mailto:' + pre + '@' + after);
		if (textPre) $(this).text(textPre + '@' + textAfter);
	});
  
	/* left/right placement of tooltips */
	if ($('.content')[0]) {
  	var leftPosContent = $('.content').offset().left;
  	var widthContent   = $('.content').width();
  	$('.hint--bottom').each(function(){
  	  var leftPos = $(this).offset().left;
  	  if (widthContent / 2 < leftPos - leftPosContent) $(this).addClass('hint--leftside');
  	});
	}
	
	/* collapse and popup large tables */
	$('.table-zoom')
	.each(function(){
	  var minMargin   = 250;
	  var zoombutton  = $(this);
	  var table       = zoombutton.parent();
	  var tableHeight = table.height();
	  var positionTop = tableHeight > 600 ? minMargin : tableHeight / 2;
	  
	  zoombutton.css('top',positionTop+'px');
	  if (tableHeight > 600) {
   	  var tableTop = table.offset().top;
      $(window).scroll(function(){
        var tableScrollPos = $(this).scrollTop() - tableTop;
   	    if (tableScrollPos > 0 && tableScrollPos < tableHeight - tableTop - minMargin) {
     	    positionTop = minMargin + tableScrollPos;
     	    zoombutton.css('top',positionTop+'px');
   	    }
   	  });
   	}
	})
	.click(function(){
	  $(this).parent().addClass('popup');
	})
	;
	
	$('.table-close').click(function(){
	  $(this).parent().removeClass('popup');
	});
	
	/* fix left menu on page scroll */
  $(window).scroll(function(){
    var navHeight  = $('.sidebar-left').height();
    var pageHeight = $('.content').height();
    var maxMT      = pageHeight - navHeight - 80;
    if ($(window).height() > navHeight + 20 && pageHeight > navHeight && pageHeight > 1000) {
      var scrollPos = $(this).scrollTop();
      var margintop = scrollPos > 240 ? scrollPos - 240 : 0;
      margintop     = margintop > maxMT ? maxMT : margintop;
      $('.sidebar-left').css('margin-top',margintop);
    }
  });
  
   accordionInit();
   
});

function accordionInit() {
  cookieAcd = $.cookie(cookieNameAcd);
  if (cookieAcd == null || cookieAcd == '') cookieAcd = '';

  var nodes = cookieAcd.split(';');
  var openCount = 0;

  for(var i=0; i<nodes.length-1; i++) {
    $('.accordion > li > a[data-nid='+nodes[i]+']').each(function(){
      toggleAccordion($(this),false);
      openCount++;
    });
  }
  if (openCount==$('.accordion > li > a').length) {
    $('#expand-collapse-all')
    .data('state','collapse')
    .text($('#expand-collapse-all').data('collapse'));
  }

  if (window.location.hash) toggleAccordion($('.accordion > li > a:not(.active)'+window.location.hash),true);

  $('#menu > ul > li:last-child').addClass('last');

  $('.accordion > li > a:not([href])').click(function(){
    toggleAccordion($(this),true);
  });

  $('#expand-collapse-all').click(function(){
    if ($(this).data('state')=='expand') {
      $(this).data('state','collapse');
      $(this).text($(this).data('collapse'));
      $('.accordion > li > a:not(.active)').each(function(){
        toggleAccordion($(this),true);
      });
    } else {
      $(this).data('state','expand');
      $(this).text($(this).data('expand'));
      $('.accordion > li > a.active').each(function(){
        toggleAccordion($(this),true);
      });
    }
  });

  if ($('.accordion > li').length == 1) {
    if($('.accordion > li > a.active').length == 0) {
      $(this).data('state','collapse');
      $(this).text($(this).data('collapse'));
      $('.accordion > li > a').each(function(){
        toggleAccordion($(this),true);
      });
    }
  }
}

function toggleAccordion(object,setCookie) {
  var nodeId = object.data('nid');
  if (object.hasClass('active')) {
    object.removeClass('active');
    object.next().slideUp(100);
    if (setCookie) {
      cookieAcd = cookieAcd.replace(nodeId + ';','');
      $.cookie(cookieNameAcd,cookieAcd,{path:'/'});
    }
  } else {
    object.addClass('active');
    object.next().slideDown(100);
    if (setCookie) {
      if (cookieAcd.indexOf(nodeId) == -1) cookieAcd += nodeId + ';';
      $.cookie(cookieNameAcd,cookieAcd,{path:'/'});
    }
   
	$('.table-zoom')
	.each(function(){
	  var minMargin   = 250;
	  var zoombutton  = $(this);
	  var table       = zoombutton.parent();
	  var tableHeight = table.height();
	  var positionTop = tableHeight > 600 ? minMargin : tableHeight / 2;
	  
	  zoombutton.css('top',positionTop+'px');
	  if (tableHeight > 600) {
   	  var tableTop = table.offset().top;
      $(window).scroll(function(){
        var tableScrollPos = $(this).scrollTop() - tableTop;
   	    if (tableScrollPos > 0 && tableScrollPos < tableHeight - tableTop - minMargin) {
     	    positionTop = minMargin + tableScrollPos;
     	    zoombutton.css('top',positionTop+'px');
   	    }
   	  });
   	}
	})
	.click(function(){
	  $(this).parent().addClass('popup');
	})
	;   
   
   
  }
}